import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment-hijri';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useToastContextWithError } from 'contexts/RootContext';
import { ID_PATTERN, EMAIL_PATTERN, PASSWORD_PATTERN, MOBILE_PATTERN, FULLNAME_PATTERN } from '../../../shared/utils/regax';
import sharedApi from '../../../shared';
import { validateRecaptchToken } from '../../../services/axios/api';

const { Button, InputField, Card, PasswordValidation, LogoHeader, Toaster } = sharedApi.components;
export default function ApplicantRegistration(props) {
  const [searchParams] = useSearchParams();
  const recaptchaRef = React.createRef();
  const isProvider = !!+searchParams.get('provider');
  const isApplicant = !!+searchParams.get('applicant');
  const errorToast = useToastContextWithError();

  const { registerActions, error } = props;
  const Navigate = useNavigate();

  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const [passworError, setPasswordError] = useState('');
  const [specialChar, setSpecialChar] = useState('');
  const [number, setNumber] = useState('');
  const [uppercase, setUppercase] = useState('');
  const [lowercase, setLowercase] = useState('');
  const [DOB, setDOB] = useState('');
  const [email, setEmail] = useState('');
  const [validToken, setValidToken] = useState(false);

  const onSubmit = async (data) => {
    // if (validToken) {
      let momentDOB = moment(DOB, 'iDD/iMM/iYYYY');
      let formaedDate = momentDOB.format('YYYY/M/D').replaceAll('/', '-');
      data.dateOfBirth = formaedDate;
      setEmail(data.email);
      data.email = data.email.toLowerCase();
      if (isProvider) registerActions.serviceProviderRegister(data);
      if (isApplicant) registerActions.applicantRegister(data);
    // }
  };

  useEffect(() => {
    if (props.success) {
      if (isProvider) {
        Navigate('/otp?provider=1', {
          state: {
            registered: true,
            email,
          },
        });
      }
      if (isApplicant) {
        Navigate('/otp?applicant=1', {
          state: {
            registered: true,
            email,
          },
        });
      }
    } else if (props.error) {
      errorToast(Object.values(error).join(' '));
    }
    // eslint-disable-next-line
  }, [props]);

  const validateDate = (dateOfBirth) => {
    var today = moment().format('iDD/iMM/iYYYY');
    var todayYear = moment(today, 'iDD/iMM/iYYYY').iYear();
    var dateOfBirthYear = moment(dateOfBirth, 'iDD/iMM/iYYYY').iYear();
    var age = todayYear - dateOfBirthYear;
    setDOB(dateOfBirth);
    return age > 11;
  };
  function handleKeyUp() {
    setPasswordError(
      <PasswordValidation capsLetterFlag={uppercase} smallLetterFlag={lowercase} numberFlag={number} specialCharFlag={specialChar} />
    );
  }

  const checkRecaptcha = async () => {
    const token = recaptchaRef.current.getValue();
    const validate = await validateRecaptchToken({ recatcha_token: token });
    if (validate?.code === 200) setValidToken(true);
    else {
      window.grecaptcha.reset(recaptchaRef.current.getWidgetId());
      errorToast('فشل في التحقق');
    }
  };

  return (
    <div>
      <div className='background-full__screen '>
        <div className='secondbg-full__screen'></div>
        <div className='thirdbg-full__screen'></div>
        <div className='fivebg-full__screen'></div>
        <LogoHeader white />
        <div className='flex flex--center'>
          <Card
            wide='card--wide-1'
            title='انشاء حساب'
            subTitle={isProvider ? 'قم بإدخال بيانات ممثل المنشأة لبدء تقديم الخدمات' : 'قم بإدخال البيانات المطلوبة لبدء رحلة التعلُم'}
            toaster={error && <Toaster message={Object.values(error).join(', ')} type='warning' />}
          >
            <form>
              <div className='card__body--wide'>
                <InputField
                  title='nationalId'
                  placeholder='nationalIdNumber'
                  type='text'
                  name='nationalId'
                  error={errors.nationalId && errors.nationalId.message}
                  {...register('nationalId', {
                    required: 'fieldRequired',
                    pattern: {
                      value: ID_PATTERN,
                      message: 'nationalIdInvalid',
                    },
                    onChange: (e) =>
                      setValue(
                        'nationalId',
                        /[^\d+$]/.test(e.target.value) ? e.target.value.slice(0, e.target.value.length - 1) : e.target.value
                      ),
                  })}
                />

                <InputField
                  title='fullName'
                  placeholder='fullName'
                  type='text'
                  name='username'
                  error={errors.username && errors.username.message}
                  {...register('username', {
                    required: 'fieldRequired',
                    pattern: {
                      value: FULLNAME_PATTERN,
                      message: 'fullNameInvalid',
                    },
                  })}
                />

                <Controller
                  title='dateOfBirth'
                  control={control}
                  name='dateOfBirth'
                  rules={{
                    required: 'fieldRequired',
                    validate: {
                      validateDa: (v) => validateDate(v) || 'ageInvalid',
                    },
                  }}
                  render={({ field: { onChange } }) => (
                    <InputField
                      onChange={onChange}
                      value={DOB}
                      title='dateOfBirth'
                      placeholder='selectDate'
                      type='date'
                      error={errors.dateOfBirth && errors.dateOfBirth.message}
                    />
                  )}
                />
                <InputField
                  title='email'
                  placeholder='example@example.com'
                  type='email'
                  name='email'
                  error={errors.email && errors.email.message}
                  {...register('email', {
                    required: 'fieldRequired',
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: 'registrEmailInvalid',
                    },
                  })}
                />
                <InputField
                  title='mobileNumber'
                  placeholder='9665XXXXXXXX'
                  type='number'
                  name='mobileNumber'
                  error={errors.mobileNumber && errors.mobileNumber.message}
                  {...register('mobileNumber', {
                    required: 'fieldRequired',
                    pattern: {
                      value: MOBILE_PATTERN,
                      message: 'mobileInvalid',
                    },
                  })}
                />
                <InputField
                  name='password'
                  title='password'
                  placeholder='password'
                  type='password'
                  onKeyUp={handleKeyUp}
                  error={passworError}
                  {...register('password', {
                    validate: {
                      specialChar: (value) => (/[#!@$%^&*]/.test(value) ? setSpecialChar('valid') : setSpecialChar('invalid')),
                      number: (value) => (/[0-9]/.test(value) ? setNumber('valid') : setNumber('invalid')),
                      uppercase: (value) => (/[A-Z]/.test(value) ? setUppercase('valid') : setUppercase('invalid')),
                      lowercase: (value) => (/[a-z]/.test(value) ? setLowercase('valid') : setLowercase('invalid')),
                      eightCharsOrGreater: (value) => PASSWORD_PATTERN.test(value),
                    },
                  })}
                />

                <InputField
                  name='confirmPassword'
                  title='confirmPassword'
                  placeholder='confirmPassword'
                  type='password'
                  error={errors.confirmPassword && errors.confirmPassword.message}
                  {...register('confirmPassword', {
                    required: 'fieldRequired',
                    validate: (value) => value === watch('password') || 'matchPasswordInvalid',
                  })}
                />
              </div>
              {/* <ReCAPTCHA
                className='recaptch'
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCH_SITE_KEY}
                onChange={checkRecaptcha}
                onExpired={() => setValidToken(false)}
                hl='ar'
              /> */}
            </form>
            <div className='card__btn'>
              <Button
                buttonStyle='btn--primary'
                titleButton='أنشئ حساب'
                // disabled={!isValid || !validToken}
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
            {/* <p className='card__desc' style={{ textAlign: 'center' }}>
              هل لديك حساب؟
              <Link className='card__desc__link' to={'/applicant-login'}>
                تسجيل الدخول
              </Link>
            </p> */}
          </Card>
        </div>
      </div>
    </div>
  );
}
